@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoeui.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url("../fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-LightItalic";
    src: url("../fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url("../fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Thin";
    src: url("../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-ThinItalic";
    src: url("../fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}
.mat-header-cell {
    color: #212529;
}
.mat-tab-label, .mat-tab-link {
    color: #212529;
    opacity: 1 !important;
}

input::placeholder {
    color: #212529 !important;
    opacity: 1 !important;
  }
  .mat-form-field-label {
    color: #212529 !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #f44336 !important;
}